<template>
    <div class="detail-container" ref="search-container" style="height: 100%">
        <div class="detail-header">
            <!-- <div class="col-md-12 d-flex align-items-center justify-content-between"> -->
            <div class="detail-title" ref="search-header">
                <div class="title">
                    <!-- <label class="title mb-0" for="propCode">{{ `관제점 ${type !== 'global' ? '선택' : '조회'}` }}</label> -->
                    <div class="input-group">
                        <div class="btn-group" id="propCode">
                            <button
                                type="button"
                                class="btn btn-md"
                                :class="{
                                    'btn-primary': searchType === 'point',
                                    'btn-light': searchType !== 'point',
                                }"
                                @click="selectSearchType('point')"
                            >
                                {{ "관제점 정보로 찾기" }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-md"
                                :class="{
                                    'btn-primary': searchType === 'device',
                                    'btn-light': searchType !== 'device',
                                }"
                                @click="selectSearchType('device')"
                            >
                                {{ "연동장치로 찾기" }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-md"
                                :class="{
                                    'btn-primary': searchType === 'equip',
                                    'btn-light': searchType !== 'equip',
                                }"
                                @click="selectSearchType('equip')"
                            >
                                {{ "설비 정보로 찾기" }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <span
                        class="setting-icon status-icon"
                        @click="pointAddOrDelete('newData')"
                        v-b-tooltip.hover
                        :title="$t('관제점 추가')"
                    >
                        <i class="fas fa-plus-square" aria-hidden="true"></i>
                    </span>
                    <span
                        class="setting-icon"
                        @click="pointAddOrDelete('delete')"
                        v-b-tooltip.hover
                        :title="$t('관제점 다중 삭제')"
                        style="color: #ed7d31;"
                    >
                        <i class="fas fa-trash-alt" aria-hidden="true"></i>
                    </span>
                    <span
                        class="setting-icon me-3"
                        @click="openEditMultiPointModal"
                        v-b-tooltip.hover
                        title="다중 관제점 수정"
                        ><i class="fas fa-edit"></i
                    ></span>
                    <span
                        class="setting-icon me-3"
                        @click="openTableViewSelect"
                        v-b-tooltip.hover
                        title="표시 항목 선택"
                        ><i class="fas fa-list"></i
                    ></span>
                    <!-- <span class="setting-icon" @click="closeSearchPoint"><i class="fas fa-times"></i></span> -->
                </div>
            </div>

            <div class="detail-title" ref="search-select">
                <div class="title" style="width: 100%">
                    <div class="title flex-grow-1" v-if="searchType == 'device'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">장치 유형</span>
                            </div>
                            <select
                                v-model="selectOptions.firstOption"
                                id="baseCond1"
                                class="form-control"
                                @change="selectFirstOption"
                            >
                                <option :value="null">선택</option>
                                <option
                                    v-for="meterType in searchList"
                                    :key="meterType.meterTypeCode"
                                    :value="meterType.meterTypeCode"
                                >
                                    {{ meterType.meterTypeName ?? "-" }}
                                </option>
                            </select>
                        </div>

                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">연동 장치</span>
                            </div>
                            <select
                                v-model="selectOptions.secondOption"
                                id="baseCond2"
                                class="form-control"
                                @change="selectSecondOption"
                            >
                                <option :value="null">선택</option>
                                <option
                                    v-for="device in secondOptList"
                                    :key="device.parentFacilityIdx"
                                    :value="device.parentFacilityIdx"
                                >
                                    {{ device.parentFacilityAlias }}
                                </option>
                            </select>
                        </div>

                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">서브 모듈</span>
                            </div>
                            <!-- <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control "
                                @change="searchPointList"> -->
                            <select
                                v-model="selectOptions.thirdOption"
                                id="baseCond3"
                                class="form-control"
                                @change="handleSearch"
                            >
                                <option :value="null">선택</option>
                                <option
                                    v-for="facility in thirdOptList"
                                    :key="facility.facilityIdx"
                                    :value="facility.facilityIdx"
                                >
                                    {{ facility.facilityAlias }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="title flex-grow-1" v-if="searchType == 'point'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">관제점 유형</span>
                            </div>
                            <select
                                v-model="selectOptions.firstOption"
                                id="baseCond1"
                                class="form-control"
                                @change="selectFirstOption"
                            >
                                <option :value="null">선택</option>
                                <option
                                    v-for="virtual in searchList"
                                    :key="virtual.isVirtual"
                                    :value="virtual.isVirtual"
                                >
                                    {{ virtual.isVirtualName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">관제점 그룹</span>
                            </div>
                            <select
                                v-model="selectOptions.secondOption"
                                id="baseCond2"
                                class="form-control"
                                @change="selectSecondOption"
                            >
                                <option :value="null">관제점 그룹</option>
                                <option v-for="group in secondOptList" :key="group.ptGroup" :value="group.ptGroup">
                                    {{ group.ptGroupName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <!-- <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">단위 유형</span>
                            </div>
                            <select v-model="selectOptions.thirdOption" id="baseCond3" class="form-control"
                                @change="handleSearch">
                                <option :value="null">선택</option>
                                <option v-for="(code) in thirdOptList" :key="code.unitLargeCode"
                                    :value="code.unitLargeCode">
                                    {{ code.unitLargeCodeName }}
                                </option>
                            </select> -->
                            <!-- badblock-20240822 -->
                            <!-- <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">사용 용도</span>
                            </div> -->
                            <div class="input-group-prepend">
                                <span for="baseCond3" class="input-group-text">관제점 검색</span>
                            </div>
                            <input
                                v-model="optional.filterText"
                                type="text"
                                class="form-control"
                                id="filterText"
                                @keydown.enter="handleSearch"
                                :placeholder="'검색 텍스트 입력'"
                            />
                            <!-- <select
                                v-model="selectOptions.thirdOption"
                                id="baseCond3"
                                class="form-control"
                                @change="handleSearch"
                            >
                                <option :value="null">선택</option>
                                <option v-for="(code, index) in usageList" :key="index" :value="code.value">
                                    {{ code.text }}
                                </option>
                            </select> -->
                        </div>
                    </div>

                    <div class="title flex-grow-1" v-if="searchType == 'equip'">
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond1" class="input-group-text">설비 유형</span>
                            </div>
                            <select
                                v-model="selectOptions.firstOption"
                                id="baseCond1"
                                class="form-control"
                                @change="selectFirstOption"
                            >
                                <option :value="null">선택</option>
                                <option v-for="type in searchList" :key="type.equipType" :value="type.equipType">
                                    {{ type.equipTypeName }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="input-group-prepend">
                                <span for="baseCond2" class="input-group-text">연동 장치</span>
                            </div>
                            <select
                                v-model="selectOptions.secondOption"
                                id="baseCond2"
                                class="form-control"
                                @change="selectSecondOption"
                            >
                                <option :value="null">연동 장치</option>
                                <option v-for="type in secondOptList" :key="type.deviceIdx" :value="type.deviceIdx">
                                    {{ type.deviceAlias }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mr-2">
                            <div class="intput-group-prepend">
                                <label for="baseCond3" class="input-group-text">설비</label>
                            </div>

                            <select
                                v-model="selectOptions.thirdOption"
                                id="thirdOption"
                                class="form-control"
                                @change="handleSearch"
                            >
                                <option :value="null">선택</option>
                                <option v-for="equip in thirdOptList" :key="equip.equipIdx" :value="equip.equipIdx">
                                    {{ equip.equipName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- 공통 : 버튼 컨트롤  -->
                    <div class="title btn-group">
                        <button type="button" class="btn btn-primary" @click="handleSearch">
                            조회
                        </button>
                        <button type="button" class="btn btn-secondary" @click="toggleSearchMode">
                            <i class="fas fa-angle-double-down" :class="{ 'rotate-90': openOptional }"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- 확장 검색 영역 -->
            <b-collapse v-model="openOptional" id="OptionalSelect" style="width: 100%">
                <div class="detail-title" ref="search-select-optional">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">결과 내 검색</span>
                        </div>
                        <select
                            v-model="optional.ptTypeCode"
                            id="ptTypeCode"
                            class="form-control"
                            @change="applyFilter"
                        >
                            <option :value="null">집계 유형</option>
                            <option v-for="(code, index) in ptTypeList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>

                        <select
                            v-model="optional.unitSmallCode"
                            id="unitSmallCode"
                            class="form-control"
                            @change="applyFilter"
                        >
                            <option :value="null">단위</option>
                            <option v-for="(code, index) in unitList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select
                            v-model="optional.energySourceCode"
                            id="energySourceCode"
                            class="form-control"
                            @change="applyFilter"
                        >
                            <option :value="null">사용 에너지원</option>
                            <option v-for="(code, index) in energyList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select
                            v-model="optional.itemUseageCode"
                            id="itemUseageCode"
                            class="form-control"
                            @change="applyFilter"
                        >
                            <option :value="null">사용 용도</option>
                            <option v-for="(code, index) in usageList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <select
                            v-model="optional.applyZoneIdx"
                            id="applyZoneIdx"
                            class="form-control"
                            @change="applyFilter"
                        >
                            <option :value="null">적용 구역</option>
                            <option v-for="(code, index) in zoneList" :key="index" :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                        <!-- <input
                            v-model="optional.filterText"
                            type="text"
                            class="form-control"
                            id="filterText"
                            @keydown.enter="applyFilter"
                            :placeholder="'검색 텍스트 입력'"
                        /> -->
                    </div>
                </div>
            </b-collapse>
        </div>
        <div v-if="searchList" class="form-row flex-grow-1" :style="{ height: listHeight }">
            <div v-if="!pointList && !isLoading" class="col-md-12 detail-placeholder">
                <!-- 관제점 현황 요약 정보 삽입 -->
                <point-overview />
            </div>

            <div class="col-md-12 pointListContainer" style="position: relative;">
                <vue-good-table
                    :key="tableKey"
                    ref="resultTable"
                    style="margin-top: 0.5rem; height: 100%; width: 100%"
                    compactMode
                    :columns="vgtColumns"
                    :rows="pointList"
                    :row-style-class="rowStyleClassFn"
                    :fixed-header="true"
                    :max-height="openOptional ? optionTableHeight : tableMaxHeight"
                    :selected-rows="[clickedRow]"
                    :select-options="{
                        enabled: true,
                        disableSelectInfo: true,
                        selectOnCheckboxOnly: true,
                    }"
                    @on-row-click="handleRowClick"
                    @on-select="onSelectRow"
                    @on-select-all="onSelectAllRows"
                    :pagination-options="paginationOptions"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                    @on-selected-rows-change="onSelectedRowsChange"
                    :total-rows="countTotalData"
                >
                    <div slot="emptystate" style="text-align: center">
                        {{ $t("표시할 데이터가 없습니다.") }}
                    </div>
                    <template v-slot:table-row="props">
                        <div v-for="column in vgtColumns" :key="column.field" @click="handleCellClick(props, $event)">
                            <template v-if="props.column.field === column.field">
                                <template v-if="column.field === 'checkbox'">
                                    <div @click.stop="toggleCheckbox(props.row)">
                                        <input type="checkbox" v-model="props.isSelected" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div v-html="formatValue(props.column.field, props.row)"></div>
                                </template>
                            </template>
                        </div>
                    </template>
                </vue-good-table>

                <!-- 로딩 오버레이 -->
                <div v-if="isLoading" class="loading-overlay">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
            </div>
        </div>

        <!-- 다중 관제점 수정 팝업 -->
        <!-- selectedPtIdxs: [2572, 2573, 2574, 2576], -->
        <EditMultiPointModal
            ref="editMultiPointModal"
            :ptIdxs="selectedPtIdxs"
            @confirm="handleEditMultiPoint"
            @clear="clearSelectedPtIdxs"
            @closeDetailPanel="closeDetailPanel"
        />
        <!-- 목록 표시 항목 선택 팝업 -->
        <SetPointListOptionsModal ref="setPointListOptionsModal" @confirm="handlePointListOptions" />
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import PointOverview from "./PointOverview.vue";

// Modal
import EditMultiPointModal from "./modal/EditMultiPointModal.vue";
import SetPointListOptionsModal from "./modal/SetPointListOptionsModal.vue";
import { mapState, mapMutations } from "vuex";

export default {
    props: {
        data: { type: Object },
        // transCodes: { type: Array },
        tableMaxHeight: { type: String, default: "" },
        optionTableHeight: { type: String, default: "" },
        type: { type: String, default: "" },
    },
    components: {
        PointOverview,
        EditMultiPointModal,
        SetPointListOptionsModal,
    },
    data() {
        return {
            /** common */
            searchList: null,
            pointList: null,
            basePointList: null,
            // searchType: null,
            searchType: "point",
            listHeight: null,

            /** 필수 선택 변수 */
            selectOptions: {
                firstOption: null,
                secondOption: null,
                thirdOption: null,
            },
            secondOptList: null,
            thirdOptList: null,

            /** optional select */
            optional: {
                ptTypeCode: null,
                unitSmallCode: null,
                energySourceCode: null,
                itemUseageCode: null,
                applyZoneIdx: null,
                filterText: null,
            },
            openOptional: false,

            /** 추가 옵션 select list */
            ptTypeList: null,
            unitList: null,
            energyList: null,
            usageList: null,
            zoneList: null,

            /** table */
            columns: [],
            vgtColumns: [],
            vgtRows: null,
            clickedRow: null,
            selectTableView: null,
            selectedPtIdxs: null, // 선택된 관제점 ID 배열
            selectedRows: [],
            tableKey: 0,

            /** 기타 */
            isLoading: false,
            offset: 0, // 데이터를 가져올 때 사용할 offset
            limit: 10, // 한번에 가져올 데이터 수
            countDataFromServer: 0, // 프론트에서 측정한 현재까지 로드된 데이터 갯수
            countTotalData: 0, // api로 받아온 데이터의 총 갯수
            // useFilter:true, //필터를 사용할지말지
            // toggleFields: ['enabled'],
            currentPage: 1,

            paginationOptions: {
                enabled: true,
                mode: "pages", // or 'records'
                perPage: 10, // 페이지당 데이터 개수
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                jumpFirstOrLast: true,
            },

            transCodes: [
                {
                    field: "energySourceCode",
                    codes: this.commonCodes?.energySourceCode,
                },
                {
                    field: "itemUseageCode",
                    codes: this.commonCodes?.itemUseageCode,
                },
                {
                    field: "unitLargeCode",
                    codes: this.unitTypes,
                },
                {
                    field: "unitSmallCode",
                    codes: this.units,
                },
                {
                    field: "applyZoneIdx",
                    codes: this.zoneInfo,
                },
                {
                    field: "isVirtual",
                    codes: this.commonCodes?.isVirtual,
                },
                {
                    field: "enabled",
                    codes: this.commonCodes?.enabled,
                },
                {
                    field: "ptGroup",
                    codes: this.commonCodes?.pointGroup,
                },
                {
                    field: "ptTypeCode",
                    codes: this.commonCodes?.ptTypeCode,
                },
                {
                    field: "itemPropCode",
                    codes: this.commonCodes?.itemPropCode,
                },
                {
                    field: "purpsUseCode",
                    codes: this.commonCodes?.purpsUseCode,
                },
            ],
        };
    },
    async created() {
        await this.getFacilityClass();
        await this.listContainerHeight();
        await this.setColumns();
        await this.initOptionalSelectList();
        // this.optional.unitSmallCode = this.data ? this.data.unit : null;
    },
    async mounted() {
        const { pointOption } = this;

        const finalSearchType = pointOption?.searchType ?? this.searchOptions.searchType;
        const finalFirstOption = pointOption?.firstOption ?? this.searchOptions.firstOption;
        const finalSecondOption = pointOption?.secondOption ?? this.searchOptions.secondOption;
        const finalThirdOption = pointOption?.thirdOption ?? this.searchOptions.thirdOption;

        if (finalSearchType) {
            this.searchType = finalSearchType;
            if (this.searchType === "device") await this.getFacilityClass();
            else if (this.searchType === "point") await this.getPointClass();
            else await this.getEquipClass();
        }

        // 관제점 유형의 물리 관제점 값이 '0'도 허용.
        if (finalFirstOption !== undefined && finalFirstOption !== null) {
            this.selectOptions.firstOption = finalFirstOption;
            await this.selectFirstOption();
        }
        if (finalSecondOption) {
            this.selectOptions.secondOption = finalSecondOption;
            await this.selectSecondOption();
        }
        if (finalThirdOption) {
            this.selectOptions.thirdOption = finalThirdOption;
        }

        if (this.searchOptions.firstOption != null) {
            await this.searchPointList();
        }
        if (this.pointList) {
            // pointList중 마지막 선택중인 row를 찾아서 클릭효과를 에뮬레이션함.
            // originalIndex는 선택된 row의 index로 handleRowClick에서 감지하여 선택된 효과를 부여함.
            let found = null;
            for (let i = 0; i < this.pointList?.length; i++) {
                if (this.pointList[i].ptIdx == this.searchOptions?.clickedRowId) {
                    found = this.pointList[i];
                    found.originalIndex = i;
                    break;
                }
            }
            if (found) {
                this.handleRowClick({ row: found });
            }
        }
    },

    beforeDestroy() {},
    computed: {
        ...mapState({
            searchOptions: (state) => state.searchOptions?.pointInfo || {},
            commonCodes: (state) => state.commonCodes || {},
            zoneInfo: (state) => state.zoneInfo || [],
            units: (state) => state.units || [],
            unitTypes: (state) => state.unitTypes || [],
            pointListOptions: (state) => state.pointListOptions || [],
        }),
        isSearchReady() {
            if (this.searchType === "point") {
                return true;
            } else if (this.searchType === "device" || this.searchType === "equip") {
                return (
                    this.selectOptions.firstOption !== null &&
                    this.selectOptions.secondOption !== null &&
                    this.selectOptions.thirdOption !== null
                );
            }
            return false;
        },
        // pointListOptions() {
        //     return this.$store.state.pointListOptions;
        // },
        //확장 검색영역의 데이터변경을 감지하여 테이블의 페이지네이션에 데이터를 변경하기위함.
        // extendedOptions() {
        //     return this.optional;
        // },
    },
    watch: {
        searchType: {
            deep: true,

            async handler(newVal) {
                this.offset = 0;
                this.countDataFromServer = 0;
                this.countTotalData = 0;
                if (newVal == "device") await this.getFacilityClass();
                else if (newVal == "point") await this.getPointClass();
                else await this.getEquipClass();
            },
        },
        openOptional: {
            deep: true,
            handler(newVal) {
                // this.offset=0;
                // this.countDataFromServer=0;
                // this.countTotalData=0;
                if (newVal === false) this.initOptionalSelect();
            },
        },
        selectOptions: {
            deep: true,
            handler(newVal) {
                if (this.searchType === "point") {
                    if (newVal.firstOption === null && newVal.secondOption === null && newVal.thirdOption === null) {
                        return;
                    }
                } else {
                    if (newVal.thirdOption === null) {
                        return;
                    }
                }
                this.paginationOptions = {
                    ...this.paginationOptions,
                    setCurrentPage: 1,
                };
                this.resetOffsetAndSearch();
                this.tableKey++;
            },
        },
        optional: {
            deep: true,
            handler(newVal) {
                if (this.searchType !== "point" && this.selectOptions.thirdOption === null) {
                    return;
                }
                if (
                    this.searchType === "point" &&
                    this.selectOptions.firstOption === null &&
                    this.selectOptions.secondOption === null &&
                    this.selectOptions.thirdOption === null
                ) {
                    return;
                }
                const allNull = Object.values({ ...newVal }).every((v) => v === null);
                this.paginationOptions = {
                    ...this.paginationOptions,
                    setCurrentPage: 1,
                };
                if (allNull) {
                    this.resetOffsetAndSearch();
                }
                this.tableKey++;
            },
        },
    },

    destroyed() {},
    methods: {
        ...mapMutations(["SET_SEARCH_OPTIONS"]),
        setSearchOptions(options) {
            this.SET_SEARCH_OPTIONS(options);
        },
        resetOffsetAndSearch() {
            // 1) offset(오프셋)과 currentPage를 1페이지로 초기화
            this.offset = 0;
            this.currentPage = 1;

            // 2) 실제 검색 함수 호출
            this.handleSearch();
        },
        pointAddOrDelete(type) {
            if (type === "newData") {
                this.$emit("pointAddOrDelete", type);
            } else if (type === "delete") {
                this.$emit("pointAddOrDelete", type, this.selectedPtIdxs);
            }
        },
        closeDetailPanel() {
            this.$emit("closeDetailPanel");
            this.searchPointList();
        },
        onSelectedRowsChange(rows) {
            this.selectedPtIdxs = rows.selectedRows.map((row) => row.ptIdx);
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
            this.offset = (this.currentPage - 1) * this.limit; // 페이지 변경 시 오프셋 계산
            // this.searchPointList(); // 데이터 가져오기
            this.handleSearch();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage; // 페이지당 데이터 수 변경
            this.offset = 0; // 오프셋 초기화
            // this.searchPointList(); // 데이터 가져오기

            this.handleSearch();
        },
        toggleSearchMode() {
            this.openOptional = !this.openOptional;
        },
        // 장치 분류체계 조회
        async getFacilityClass() {
            try {
                let result = await backEndApi.points.getFacilityClass();
                if (result.status == 200) {
                    this.searchList = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },

        // 관제점정보 분류체계 조회
        async getPointClass() {
            try {
                let result = await backEndApi.points.getPointClass();
                if (result.status == 200) {
                    this.searchList = result.data.filter((item) => item.isVirtual || item.isVirtual == 0);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },

        // 설비 분류체계 조회
        async getEquipClass() {
            try {
                let result = await backEndApi.points.getEquipClass();
                if (result.status == 200) {
                    this.searchList = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },

        // selectOptions 초기화
        initSelectOptions() {
            this.selectOptions = {
                firstOption: null,
                secondOption: null,
                thirdOption: null,
            };
        },

        // optional select 초기화
        initOptionalSelect() {
            this.optional = {
                ptTypeCode: null,
                unitSmallCode: null,
                energySourceCode: null,
                itemUseageCode: null,
                applyZoneIdx: null,
                filterText: null,
            };
        },

        // firstOption Select Event
        async selectFirstOption() {
            // this.$emit("clear-detail");
            this.selectOptions.secondOption = null;
            this.selectOptions.thirdOption = null;
            this.secondOptList = null;
            this.thirdOptList = null;
            this.pointList = null;

            // if (this.selectOptions.firstOption !== null || undefined) {
            if (this.selectOptions.firstOption != null) {
                if (this.searchType == "device") {
                    let found = this.searchList.find((item) => item.meterTypeCode == this.selectOptions.firstOption)
                        .parentFacilities;
                    if (found) this.secondOptList = found;
                } else if (this.searchType == "point") {
                    let found = this.searchList.find((item) => item.isVirtual == this.selectOptions.firstOption)
                        .ptGroups;
                    if (found) this.secondOptList = found;
                    // await this.handleSearch();
                    this.resetOffsetAndSearch();
                } else {
                    let found = this.searchList.find((item) => item.equipType == this.selectOptions.firstOption)
                        .devices;
                    if (found) this.secondOptList = found;
                }
            } else {
                this.secondOptList = null;
                this.thirdOptList = null;
            }
        },

        // secondOption Select Event
        async selectSecondOption() {
            // this.$emit("clear-detail");
            this.selectOptions.thirdOption = null;
            this.thirdOptList = null;
            this.pointList = null;
            if (this.selectOptions.secondOption != null) {
                if (this.searchType == "device") {
                    let found = this.secondOptList.find(
                        (item) => item.parentFacilityIdx == this.selectOptions.secondOption
                    ).facilities;
                    if (found) this.thirdOptList = found;
                } else if (this.searchType == "point") {
                    // badblock-20240822
                    let found = this.secondOptList.find((item) => item.ptGroup == this.selectOptions.secondOption)
                        .itemUseageCodes;
                    if (found) this.thirdOptList = found;

                    // await this.handleSearch();
                    this.resetOffsetAndSearch();
                } else {
                    let found = this.secondOptList.find((item) => item.deviceIdx == this.selectOptions.secondOption)
                        .equips;
                    if (found) this.thirdOptList = found;
                }
            } else this.thirdOptList = null;
        },

        // 조회 이벤트 핸들러
        async handleSearch() {
            // 관제점 검색이 아닌 경우 세번째 주 검색조건까지 모두 선택해야 조회 가능함.
            if (!this.isSearchReady) {
                this.alertWarning("조건을 모두 선택하세요.");
                return;
            }

            let searchOptionTemp = {
                searchType: this.searchType,
                firstOption: this.selectOptions.firstOption,
                secondOption: this.selectOptions.secondOption,
                thirdOption: this.selectOptions.thirdOption,
                clickedRowId: this.clickedRow?.ptIdx,
                currentPage: this.currentPage,
            };
            this.setSearchOptions(searchOptionTemp);

            this.pointList = []; // 초기화
            await this.searchPointList();
        },

        // 관제점 조회
        async searchPointList() {
            await this.$nextTick();

            let searchParams = { ...this.optional };
            this.basePointList = null;

            if (this.isLoading) return;

            this.isLoading = true;

            try {
                let result;
                if (this.searchType == "device") {
                    if (this.selectOptions.thirdOption) {
                        searchParams.facilityIdx = this.selectOptions.thirdOption;
                        result = await backEndApi.points.getPointInfoByFacility(searchParams, this.offset, this.limit);
                    }
                } else if (this.searchType == "point") {
                    searchParams.isVirtual = this.selectOptions.firstOption;
                    searchParams.ptGroup = this.selectOptions.secondOption;
                    searchParams.itemUseageCode = this.selectOptions.thirdOption;
                    result = await backEndApi.points.getPointInfoByGroup(searchParams, this.offset, this.limit);
                } else {
                    if (this.selectOptions.thirdOption) {
                        searchParams.equipIdx = this.selectOptions.thirdOption;
                        result = await backEndApi.points.getPointInfoByEquip(searchParams, this.offset, this.limit);
                    }
                }

                if (result && result.status == 200) {
                    //ptVal값의 소수점아래를 통일시켜 디자인 일관성을 유지하기 위함
                    result.data.data.forEach((item) => {
                        if (item.ptVal === null) return;
                        else {
                            item.ptVal = item.ptVal.toFixed(2);
                        }
                    });
                    this.pointList = result.data.data;
                    //this.pointList = result.data.data;
                    this.countTotalData = result.data.count;
                    this.basePointList = [...this.pointList];
                    await this.filterOptionalSelectList(this.pointList);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        /** table row format */
        setColumns() {
            // const that = this;
            // const pointField = this.$store.state.commonCodes.pointInfoField;
            this.vgtColumns = this.commonCodes.pointInfoField.map((item) => {
                let column = {
                    label: this.$t(item.text),
                    field: item.value,
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                    width: item.value === "ptIdx" ? "80px" : item.value === "pointAddress" ? "162px" : "",
                };
                if (item.value === "pointAddress") {
                    column.compositionFields = ["ptAddr", "ptName"];
                }
                return column;
            });
            this.columns = [...this.vgtColumns]; // 기준 columns

            this.filterColumns();
        },
        filterColumns() {
            const activeFields = this.pointListOptions.filter((item) => item.checked).map((item) => item.key);

            const filteredColumns = this.columns.filter((column) => activeFields.includes(column.field));

            this.vgtColumns = filteredColumns;
        },
        transcodeValue(field, row) {
            const that = this;

            const transCode = that.transCodes?.find((code) => code.field == field);
            if (transCode) {
                const code = transCode.codes?.find((code) => code.value == row[field]);
                if (code) return code.text;
                else return row[field] ? row[field] : "-";
            } else {
                return row[field] ? row[field] : "-";
            }
        },
        formatValue(field, row) {
            const that = this;

            let formatted = "";

            const compositionColumn = that.vgtColumns.find((col) => col.field === field && col.compositionFields);
            if (compositionColumn) {
                compositionColumn.compositionFields.forEach((subField, index) => {
                    // console.warn('formatValue compositionField:', subField, index);
                    if (!index) {
                        formatted += `<p class="mb-1" style="color: #000; font-weight: 800;">${row[subField] ??
                            "-"} </p>`;
                    } else {
                        formatted += `<p class="m-0" style="color: #777;">(${row[subField] ?? "-"})</p>`;
                    }
                });
            } else {
                if (Array.isArray(row[field])) {
                    formatted = row[field]?.length;
                } else if (field == "ptVal") {
                    formatted += `<span style="color: #000; font-weight: 800;">${row[field] ?? "-"} </span>`;
                } else {
                    formatted = this.transcodeValue(field, row);
                }
            }
            return formatted;
        },
        rowStyleClassFn(row) {
            if (row.originalIndex === this.clickedRow?.originalIndex) {
                return "selectedRow";
            }
            return "VGT-row";
        },

        handleRowClick({ row, event }) {
            const checkClick = event?.srcElement?.className === "vgt-checkbox-col" ? true : false;
            if (checkClick) return;

            this.clickedRow = row;

            // 선택된 ptIdx도 화면복원시 참조하기 위해 Store에 저장한다.
            // const searchOptions = this.$store.state.searchOptions;
            // searchOptions.pointInfo.clickedRowId = this.clickedRow?.ptIdx;

            const searchOptionsTemp = { ...this.searchOptions };
            searchOptionsTemp.clickedRowId = this.clickedRow?.ptIdx;

            this.setSearchOptions(searchOptionsTemp);

            this.$emit("select-point", this.clickedRow);
        },

        // TODO: 체크 컬럼을 클릭하면 체크만 Toggle하고 Row-Click효과를 무시해야 한다.
        // 시간관계상 숙제로 남긴다. by badblock.

        handleCellClick(props, event) {
            // 클릭된 컬럼이 체크박스 컬럼인 경우 행 클릭 무시
            if (props.column.field === "checkbox") {
                event.stopPropagation(); // 행 클릭 이벤트 막기
                this.toggleCheckbox(props.row); // 체크박스 상태 변경
            }
        },

        toggleCheckbox(row) {
            // 체크박스 상태를 토글
            const index = this.selectedRows.indexOf(row);
            if (index > -1) {
                this.selectedRows.splice(index, 1); // 이미 선택된 경우 해제
            } else {
                this.selectedRows.push(row); // 선택되지 않은 경우 선택
            }
        },

        // vue-good-table handler
        onSelectRow({ selectedRows }) {
            this.selectedRows = selectedRows;
        },

        onSelectAllRows({ selectedRows }) {
            this.selectedRows = selectedRows;
        },

        // searchType 선택
        selectSearchType(type) {
            this.initSelectOptions();
            this.initOptionalSelect();
            this.initOptionalSelectList();
            this.isLoading = false;
            this.searchList = null;
            this.pointList = null;
            this.selectedPtIdxs = null;
            if (type) this.searchType = type;
        },

        // 조회한 관제점 선택
        selectPointInfo() {
            this.$emit("select-point", this.clickedRow);
        },

        // list container height
        listContainerHeight() {
            if (!this.$refs["search-container"]) {
                console.warn("search-container가 존재하지 않습니다.");
                return;
            }
            let containerHeight = this.$refs["search-container"].clientHeight;
            let headerHeight = this.$refs["search-header"].clientHeight;
            let selectHeight = this.$refs["search-select"].clientHeight;

            if (containerHeight) {
                this.listHeight = Math.floor((containerHeight - (headerHeight + selectHeight)) * 0.9) + "px";
            }
        },

        // 관제점 선택 컴포넌트 destroyed
        closeSearchPoint() {
            this.$emit("close");
        },

        // 관제점 정보 다중 편집
        openEditMultiPointModal() {
            this.selectedPtIdxs = null;

            // 현재 관제점 목록에서 선택된 목록을 추출
            this.$refs.editMultiPointModal.showModal();
        },
        closeEditMultiPointModal() {
            this.$refs.editMultiPointModal.closeModal();
        },
        clearSelectedPtIdxs() {
            this.selectedPtIdxs = null;
        },
        async handleEditMultiPoint() {},

        // 표시 항목 선택 modal show
        openTableViewSelect() {
            this.$refs.setPointListOptionsModal.showModal();
        },
        closeTableViewSelect() {
            this.$refs.setPointListOptionsModal.closeModal();
        },
        async handlePointListOptions() {
            this.filterColumns();
        },

        initOptionalSelectList() {
            this.ptTypeList = [];
            this.unitList = [];
            this.energyList = [];
            this.usageList = [];
            this.zoneList = [];
        },
        filterOptionalSelectList(data) {
            // 검색 결과에 따라 확장검색 선택 옵션 목록을 재생성함.
            // 탐색성능과 중복 가능성을 방지하기 위해 탐색 순서를 변경함. by badblock

            // 집계유형 선택목록 재생성
            let ptTypeCodeUsed = [...new Set(data.map((item) => item.ptTypeCode))];
            const ptTypeCodes = this.commonCodes.ptTypeCode || [];
            this.ptTypeList = ptTypeCodeUsed
                .map((item) => ptTypeCodes.find((code) => code.value == item))
                .filter((item) => item != undefined);

            // 단위 선택목록 재생성
            let unitUsed = [...new Set(data.map((item) => item.unitSmallCode))];
            const unitCodes = this.units || [];
            this.unitList = unitUsed
                .map((item) => unitCodes.find((code) => code.value == item))
                .filter((item) => item != undefined);

            // 에너지원 선택목록 재생성
            let energySourceUsed = [...new Set(data.map((item) => item.energySourceCode))];
            const energySourceCodes = this.commonCodes.energySourceCode || [];
            this.energyList = energySourceUsed
                .map((item) => energySourceCodes.find((code) => code.value == item))
                .filter((item) => item != undefined);

            // badblock-20240822
            // 사용용도 선택목록 재생성

            // 관제점 검색의 경우 사용용도는 관제점 검색 세번째 조건으로 사용되기 때문에 필터링하지 않아야 한다.
            // 검색결과로 조건을 필터링하면 다른 용도를 선택할 수 없고, 관련하여 사용용도는 확장검색에서 제외해야 할 수도 있다.

            if (this.searchType !== "point" || this.selectOptions.thirdOption === null) {
                // 관제점유형(논리,물리)에 따라 사용하는 코드 체계가 다름
                // locPointUsage코드(논리)와 phscPointUsage코드(물리)를 병합하여 처리함.
                const locPointUsage = this.commonCodes.locPointUsage;
                const phscPointUsage = this.commonCodes.phscPointUsage;
                const mergedUsageCodes = [...locPointUsage, ...phscPointUsage];

                const usageMap = new Map();
                mergedUsageCodes.forEach((code) => usageMap.set(code.value, code));

                let pointUsageUsed = [...new Set(data.map((item) => item.itemUseageCode))];
                const itemUseageCodes = Array.from(usageMap.values()).filter((item) => item != undefined);
                this.usageList = pointUsageUsed
                    .map((item) => itemUseageCodes.find((code) => code.value == item))
                    .filter((item) => item != undefined);
            }

            // 구역정보 선택목록 재생성
            // codes: this.$store.state.zoneInfo,
            let applyZoneUsed = [...new Set(data.map((item) => item.applyZoneIdx))];
            const zoneCodes = this.zoneInfo || [];
            this.zoneList = applyZoneUsed
                .map((item) => zoneCodes.find((code) => code.value == item))
                .filter((item) => item != undefined);
        },
        // 확장검색 검색조건 변경 처리
        // 필터 조건을 개별로 적용하는 대신 중첩 적용되도록 적용해야 함.
        // 이에 하기 필터 조건은 하나의 applyFilterCondition 함수로 통합하여 구현함.
        applyFilter() {
            // API 조회 결과를 대상으로 filter를 적용.
            let filteredList = this.basePointList ?? [];

            // 확장검색의 각 조건들을 중첩하여 적용.
            if (this.optional.ptTypeCode)
                filteredList = filteredList.filter((item) => item.ptTypeCode == this.optional.ptTypeCode);
            if (this.optional.unitSmallCode)
                filteredList = filteredList.filter((item) => item.unitSmallCode == this.optional.unitSmallCode);
            if (this.optional.energySourceCode)
                filteredList = filteredList.filter((item) => item.energySourceCode == this.optional.energySourceCode);
            if (this.optional.itemUseageCode)
                filteredList = filteredList.filter((item) => item.itemUseageCode == this.optional.itemUseageCode);
            if (this.optional.applyZoneIdx)
                filteredList = filteredList.filter((item) => item.applyZoneIdx == this.optional.applyZoneIdx);
            if (this.optional.filterText)
                filteredList = filteredList.filter((item) => {
                    let pointText = `${item.ptAddr} ${item.ptName}`.toUpperCase();
                    return pointText.includes(this.optional.filterText.toUpperCase());
                });

            // 필터 결과 적용
            this.pointList = filteredList;
            // 필터링된 결과 갯수로 전체 데이터 갯수를 업데이트하면, 페이지네이션에 반영됨.
            this.countTotalData = filteredList?.length;
        },
    },
};
</script>

<style scoped>
.btn {
    border-color: #e0e0e0;
}

/* detail area styles */
.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 0.3rem 1rem 0 1rem;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    width: 100%;

    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.detail-header .detail-title {
    width: 100%;
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-header .detail-title .title {
    margin-bottom: 4px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    overflow: hidden;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 4px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: solid #eee 1px;
    /* border-radius: 10px; */
    /* border: solid #eee 1px; */
}

.form-group {
    margin-bottom: 0.4rem;
}

.flex-shrink-0 {
    flex-shrink: 0;
    /* label의 크기를 콘텐츠에 맞게 조정 */
}

.flex-grow-1 {
    flex-grow: 1;
    /* select 요소가 남은 공간을 차지하도록 설정 */
}

.ms-2 {
    margin-left: 0.5rem;
    /* select 요소와 label 사이의 여백 */
}

.form-label {
    margin-bottom: 0;
}

.form-control {
    flex-grow: 1;
}

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.setting-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.setting-icon:hover {
    background-color: #c7c7c7;
}

.setting-icon.active {
    color: #fff;
    background-color: #555;
}
.status-alert-icon {
    /* font-size: 16px; */
    color: #ed7d31;
    background-color: #fff;
    border: solid #ccc 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-alert-icon:hover {
    /* color: #546E7A; */
    color: #ed7d31;
    background-color: #ccc;
}

.status-alert-icon.active {
    color: #eceff1;
    background-color: #546e7a;
}
.table-view-container {
    padding: 1rem;
    border-radius: 10px;
    border: solid #eee 1px;
}

.observer-div {
    text-align: center;
    color: #888;
    padding: 10px;
}

.loading-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.pointListContainer {
    position: relative;
    height: 105%;
}

/* 각 셀도 최대 높이에 맞게 overflow 설정 */

.VGT-row {
    height: 50px !important;
}

/* .pageNation{
position: absolute;
bottom: 0px;
max-height: 63px;
} */
i {
    transition: transform 0.3s ease;
}
.rotate-90 {
    transform: rotate(180deg);
}
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* 다른 요소 위에 표시 */
}
</style>
